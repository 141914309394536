import React, { useEffect, useState } from 'react';
import TopNav from '../Common/Topnav';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // If you are using React Router
import moment from 'moment';
import BottomNavigation from '../Common/BottomNavigator';


const Notifications = () => {

    const [notificationData, setNotificationData] = useState([])

    useEffect(() => {
        setNotificationData([{
            date: "2024-05-16 20:45:00",
            title: "speed alert",
            subject: "vehicle reached 120Mph",
            user: "",
        },
        {
            date: "2024-05-16 20:45:00",
            title: "Geo fencing alert",
            subject: "vehicle reached 120Mph",
            user: "",
        },
        {
            date: "2024-05-16 20:45:00",
            title: "Low fuel  alert",
            subject: "vehicle reached 120Mph",
            user: "",
        }])
    }, [])

    return (
        <div>
            <TopNav />
            <Navbar style={{ backgroundColor: '#AC1E38', marginTop: '100px' }} expand="sm" className="py-3">
                <Container>
                    <Nav.Link as={Link} to="/dashboard">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img alt='' src="/images/back.png" style={{ marginRight: 5 }} />
                            <h4 style={{ color: 'white', margin: '2px' }}>Notifications</h4>
                        </div>
                    </Nav.Link>
                </Container>
            </Navbar>
            <div className="notification-container">
                <div class="d-flex flex-column flex-md-row  justify-content-center">
                    <div class="list-group">
                        {
                            notificationData.map((item, index) => {
                                return <div class="list-group-item list-group-item-action d-flex gap-3" aria-current="true" style={{ padding: 0 }}>
                                    <div style={{
                                        width: "100px", height: "100px", backgroundColor: '#666666',
                                        color: 'white',
                                        // display: 'grid',
                                        // alignItems: 'center',
                                        // justifyContent: 'center',
                                        fontSize: '22px'
                                    }}>
                                        <div style={{ textAlign: 'center' }} className='mt-2'>
                                            <label>
                                                {
                                                    moment(item.date).format('MMM')

                                                }
                                            </label>
                                            <br />
                                            <label >
                                                {
                                                    moment(item.date).format('DD')
                                                }
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-flex gap-2 w-100 justify-content-between py-3" >
                                        <div>
                                            <h6 class="mb-0" style={{ textTransform: 'capitalize' }}>{item.title}</h6>
                                            <p class="mb-0 opacity-75">{item.subject} <br /> at {moment(item.date).format('MM-DD-YYYY hh:mm:ss')}</p>
                                        </div>
                                        <small class="opacity-50 text-nowrap m-2"><img src='/images/next.png' /></small>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <BottomNavigation />
        </div >
    );
};

export default Notifications;
