import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BottomNavbar = () => {
    return (
        <div>
            <Navbar fixed="bottom" style={{ backgroundColor: '#AC1E38', display: 'grid' }}>
                <Nav className="justify-content-center" style={{ width: '100%' }}>
                    <Nav.Link as={Link} to="/dashboard" style={{ textAlign: 'center', color: 'white' }}>
                        <i className="bi bi-pie-chart-fill"></i>
                        <br />
                        Dashboard
                    </Nav.Link>
                    <Nav.Link as={Link} to="/actions" style={{ textAlign: 'center', color: 'white' }}>
                        <i className="bi bi-hdd-stack"></i>
                        <br />
                        Actions
                    </Nav.Link>
                    <Nav.Link as={Link} to="/search" style={{ textAlign: 'center', color: 'white' }}>
                        <i className="bi bi-search"></i>
                        <br />
                        Search
                    </Nav.Link>
                    <Nav.Link as={Link} to="/ai-results" style={{ textAlign: 'center', color: 'white' }}>
                        <i className="bi bi-calendar"></i>
                        <br />
                        AI Results
                    </Nav.Link>
                    <Nav.Link style={{ textAlign: 'center', color: 'white' }}>
                        <i className="bi bi-three-dots"></i>
                        <br />
                        More
                    </Nav.Link>
                </Nav>
                <Nav style={{ width: '100%', justifyContent: 'end' }}>
                    <div style={{ color: "white" }}>
                        <label>Beta Version - Confidential</label>
                    </div>
                </Nav>
            </Navbar>
            <br />
        </div>
    );
};

export default BottomNavbar;
