import React from 'react';
import './dashboard.css';
import TopNav from '../Common/Topnav';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // If you are using React Router
import { useNavigate } from "react-router-dom";
import BottomNavigator from '../Common/BottomNavigator';


const Dashboard = () => {
    const navigate = useNavigate()

    const handleNavigate = (path) => {
        navigate(path)
    }

    return (
        <div>
            <TopNav />
            <div className="container-fluid dashboard-container" style={{ marginTop: '80px', marginBottom: '80px', backgroundColor: '#F7F7F7' }}>
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ margin: '2px' }}>Dashboard</h1>
                    <label>Cloud Services</label>
                </div>
                <div>
                    <img src="/images/cloud-report.png" style={{ width: '350px' }} />
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-6 col-md-6 col-lg-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => handleNavigate('/actions')}>
                        <div className="card text-center text-white" style={{ height: '140px', backgroundColor: '#FFFFFF', cursor: 'pointer' }}>
                            <div className="card-body">
                                <div className="card-icon mb-2" >
                                    <img alt='' src="/images/aws.png" style={{ width: '135px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => handleNavigate('/actions')}>
                        <div className="card text-center text-white" style={{ height: '140px', backgroundColor: '#FFFFFF', cursor: 'pointer' }}>
                            <div className="card-body">
                                <div className="card-icon mb-2">
                                    <img alt='' src="/images/azure.png" style={{ width: '110px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => handleNavigate('/actions')}>
                        <div className="card text-center text-white" style={{ height: '140px', backgroundColor: '#FFFFFF', cursor: 'pointer' }}>
                            <div className="card-body">
                                <div className="card-icon mb-2">
                                    <img alt='' src="/images/gcp.png" style={{ width: '135px' }} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 mb-4" style={{ cursor: 'pointer' }} onClick={() => handleNavigate('/actions')}>
                        <div className="card text-center text-white" style={{ height: '140px', backgroundColor: '#FFFFFF', cursor: 'pointer' }}>
                            <div className="card-body">
                                <div className="card-icon mb-2">
                                    <img alt='' src="/images/server.png" style={{ width: '65px' }} />
                                    <br />
                                    <label style={{ color: 'black' }}>Server</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomNavigator />
        </div>
    );
};

export default Dashboard;
