import {
  Routes,
  Route,
  BrowserRouter,
  Navigate
} from "react-router-dom";
import NotFoundPage from "./Components/Common/Notfound";
import Dashboard from "./Components/Dashboard";
import Notifications from "./Components/Notifications";
import Actions from "./Components/Actions";
import Search from "./Components/Search";
import AIResults from "./Components/AI";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/actions" element={<Actions />} />
        <Route path="/search" element={<Search />} />
        <Route path="/ai-results" element={<AIResults />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
