import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const NotFoundPage = () => {
  return (
    <div className="container d-flex flex-column align-items-center text-center" style={{ marginTop: '8rem' }}>
      <h1 className="display-1 text-secondary mb-4">404</h1>
      <h2 className="h5 text-secondary mb-4">Oops! The page you are looking for might be in another castle.</h2>
      <Link to="/dashboard" className="btn btn-primary mt-3">
        Go Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
