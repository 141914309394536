import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // If you are using React Router

const TopNav = () => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="py-3 fixed-top">
            <Container>
                <Navbar.Brand href="/dashboard">
                    <img
                        src="/images/logo2.png" // Update this path to your logo
                        style={{ width: '200px' }}
                        className="d-inline-block align-top"
                        alt="Yasmesoft"
                    />

                </Navbar.Brand>
                <Navbar.Brand>
                    <Nav.Link as={Link} to="/notifications">
                        <i className="bi bi-bell"></i>
                    </Nav.Link>
                </Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>


                    </Nav>
                </Navbar.Collapse> */}
            </Container>
        </Navbar>
    );
};

export default TopNav;
