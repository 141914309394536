import React, { useEffect, useState } from 'react';
import './dashboard.css';
import TopNav from '../Common/Topnav';
import BottomNavigator from '../Common/BottomNavigator';
import { useNavigate } from 'react-router-dom';


const Actions = () => {
    const navigate = useNavigate()
    const [step, setStep] = useState(1)

    const handleStep = (value) => {
        setStep(value)
    }

    useEffect(() => {
        if (step === 2) {
            setTimeout(() => {
                setStep(3)
            }, 2000)
        }
        if (step === 5) {
            setTimeout(() => {
                setStep(6)
            }, 2000)
        }
        if (step === 6) {
            setTimeout(() => {
                navigate('/dashboard')
            }, 4000)
        }
    }, [step])

    return (
        <div>
            <TopNav />
            <div className="container-fluid dashboard-container" style={{ marginTop: '80px', background: '#F7F7F7' }}>
                {
                    step === 1 &&
                    <div className='mt-2'>
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ margin: '2px' }}>Device Connection</h1>
                            <label>Select your devices and hardware</label>
                        </div>
                        <div className='mt-5' style={{ display: 'gird', margin: 'inherit' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FFFFFF', height: '60px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <input id="radio1" type='radio' value="hardware" name="deviceConnection" />
                                    <label className='m-2'>IOT devices</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FFFFFF', height: '60px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', marginTop: '15px' }}>
                                <input id="radio1" type='radio' value="hardware" name="deviceConnection" />
                                <label className='m-2'>Hardware</label>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <button type="button" onClick={() => handleStep(2)} className='btn btn-sm btn-dark w-100'>CONFIGURE</button>
                        </div>
                    </div>
                }
                {
                    step === 2 &&
                    <div className='mt-2'>
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ margin: '2px' }}>Data Synchronization</h1>
                        </div>
                        <div className='mt-5' style={{ display: 'gird', margin: 'inherit' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '260px', background: '#FFFFFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                                <div style={{ display: 'grid', alignItems: 'center', justifyContent: 'center' }}>
                                    <i style={{ fontSize: '70px', textAlign: 'center' }} class="bi bi-journals"></i>
                                    <br />
                                    <label className='m-2'>Synchronizing Data....</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    step === 3 &&
                    <div className='mt-2'>
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ margin: '2px' }}>Ai Results</h1>
                            <label>Ai Pattern Confirmation</label>
                        </div>
                        <div className='mt-5' style={{ display: 'gird', margin: 'inherit' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '260px', background: '#FFFFFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                                <div style={{ display: 'grid', alignItems: 'center', justifyContent: 'center' }}>
                                    <i style={{ fontSize: '70px', color: 'green', textAlign: 'center' }} class="bi bi-check-circle-fill"></i>
                                    <br />
                                    <label className='m-2'>Ai Pattern Established</label>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <button type="button" onClick={() => handleStep(4)} className='btn btn-sm btn-dark w-100'>Automate</button>
                        </div>
                    </div>
                }
                {
                    step === 4 &&
                    <div className='mt-2' >
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ margin: '2px' }}>Data Analyzing</h1>
                            <label>Select Optimization</label>
                        </div>
                        <div className='mt-5' style={{ display: 'gird', margin: 'inherit', background: '#FFFFFF', }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <input id="radio1" type='radio' value="optimization" name="dataAnalyzing" />
                                    <label className='m-2'>Ai Optimization</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', marginTop: '15px' }}>
                                <input id="radio1" type='radio' value="standard" name="dataAnalyzing" />
                                <label className='m-2'>Standard</label>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <button type="button" onClick={() => handleStep(5)} className='btn btn-sm btn-dark w-100'>INITIATE</button>
                        </div>
                    </div>
                }
                {
                    step === 5 &&
                    <div className='mt-2'>
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ margin: '2px' }}>Device Connection</h1>
                            <label>Select your devices and hardware</label>
                        </div>
                        <div className='mt-5' style={{ display: 'gird', margin: 'inherit', height: '260px', background: '#FFFFFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                            <div style={{ textAlign: 'center' }}>
                                <i style={{ fontSize: '30px', color: 'green', textAlign: 'center' }} class="bi bi-check-circle-fill"></i>
                                <br />
                                <label className='m-2'>Hardware connection required</label>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ display: 'grid', alignItems: 'center', justifyContent: 'center' }}>
                                    <i style={{ fontSize: '75px', textAlign: 'center' }} class="bi bi-cloud-check-fill"></i>
                                    <label className='m-2'>Device establishment</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    step === 6 &&
                    <div className='mt-2'>
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ margin: '2px' }}>Data Reports</h1>
                        </div>
                        <div className='mt-5' style={{ display: 'gird', margin: 'inherit', height: '260px', background: '#FFFFFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                <div style={{ display: 'grid', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                                        <img src="/images/data-reports.png" />
                                    </div>
                                    <label className='m-2'>Data reporting back to hardware</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <BottomNavigator />
        </div >
    );
};

export default Actions;
