import React from 'react';
import './dashboard.css';
import TopNav from '../Common/Topnav';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // If you are using React Router
import { useNavigate } from "react-router-dom";
import BottomNavigator from '../Common/BottomNavigator';


const AIResults = () => {
    const navigate = useNavigate()

    const handleNavigate = (path) => {
        navigate(path)
    }

    return (
        <div>
            <TopNav />
            <div className="container-fluid dashboard-container" style={{ marginTop: '80px' }}>
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ margin: '2px' }}>AI Results</h1>
                </div>
            </div>
            <BottomNavigator />
        </div>
    );
};

export default AIResults;
